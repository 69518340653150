<template>
  <div class="confirmDialog">
    供应商 {{adjustmentData.providerName}}  将由
    <span style="color:#FF8200">
     {{adjustmentData.adjustAdvice=='1'?'一次升级到一般':adjustmentData.adjustAdvice=='2'?'一次升级到合格':adjustmentData.adjustAdvice=='3'?'一般升级到合格'
            :adjustmentData.adjustAdvice=='4'?'一般降级到一次':adjustmentData.adjustAdvice=='5'?'合格降级到一般':adjustmentData.adjustAdvice=='6'?'合格降级到淘汰'
            :adjustmentData.adjustAdvice=='7'?'一次降级到淘汰':'一般降级到淘汰'}}
    </span>
  </div>
</template>

<script>

export default {
  name: 'passDialog',
  props: ['adjustmentData'],
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>
